<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"      
      :height="$vuetify.breakpoint.mdAndUp ? 80 : 60"
    >
      <router-link to="/" class="hidden-md-and-up">
        <base-img
          :src="require('@/assets/logo.svg')"
          class="mr-3"
          contain
          max-width="52"
          width="100%"
        />
      </router-link>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"          
          v-model="activeTab"
          :hide-slider="activeTab === '/'"
          optional
        >
          <v-tab
            v-for="(item, i) in items"            
            :key="i"
            :to="item.path ? { path: item.path } : { name: item.name }"
            :ripple="false"            
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            <v-img v-if="item.path == '/'"
              :src="require('@/assets/logo.svg')"
              class="mr-3 hidden-xs-only"
              contain
              max-width="52"
              width="100%"
            />            
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-spacer />

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="drawerItems"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      activeTab: null,
      drawer: null,
      items: [                
        { name: 'О компании', path: null },
        { name: 'Услуги и цены', path: null },
        { name: '', path: '/' },
        { name: 'Статьи и новости', path: null },
        { name: 'Контакты', path: null },
      ],
      // items: [        
      //   'О компании',
      //   'Услуги и цены',
      //   'Главная',
      //   'Статьи и новости',
      //   'Контакты',
      // ],
    }),

    computed: {
      drawerItems() {
        return this.items.filter(item => !!item.name)
      }
    }
  //   mounted() {
  //     this.activeTab = '/'
  //  },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider-wrapper
      min-width: 121px

    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
